import React from "react";
import Link from "next/link";
import { parseDomain, ParseResultType } from "parse-domain";

const Logo = () => {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH;
  const skin = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
  let domainName = "USA-taxID";
  let subDomainName=[];
  const parseResult = parseDomain(typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '');
  if (parseResult.type === ParseResultType.Listed) {
    const { domain, subDomains } = parseResult;
    domainName = domain;
    subDomainName=subDomains;
  }
  return (
    <Link href="/">
      <div className="logo">
        <img
          style={{ cursor: "pointer", marginTop: skin === "irsein" ? -14 : 0 }}
          alt="Logo"
          src={domainName === "taxid-us" && !subDomainName.includes("irs") && !subDomainName.includes("ein") ? `${basePath}/images/gov-taxid_logo3.png?2024` :
            skin === "taxid"
              ? `${basePath}/images/gov-taxid_logo.png?2025`
              : skin === "irsv3" ? `${basePath}/images/usa-taxid-logo-new.png`
                : (skin === "irsein" || skin === "irs" || skin === "taxid-ein")
                  ? `${basePath}/images/ein-taxid-logo.png`
                  : `${basePath}/images/usa-taxid-logo.png?2024`
          }
        />
        {domainName === "taxid-us" && !subDomainName.includes("irs") && !subDomainName.includes("ein") ? "" : skin === "taxid" && (
          <span className="logo-title">
            <span className="logo-text">
              {domainName!=="businesstin" && "IRS "}Tax ID/EIN Application <b>(2024)</b>
            </span>
          </span>
        )}
        {(domainName !== "taxid-us" && domainName!=="ss4-ein-taxid" && (skin === "irsein" || skin === "irs" || skin === "taxid-ein")) && (
          <span className="logo-title">
            <span className="logo-text">
              EIN APPLICATION (2024)
            </span>
            <span className="logo-subtext">
              EIN PROCESSING & FILING SERVICE
            </span>
          </span>
        )}
        {domainName === "taxid-us" && !subDomainName.includes("irs") && !subDomainName.includes("ein") && (skin === "irsein" || skin === "irs" || skin === "taxid-ein") && (
          <span className="logo-title">
            <span className="logo-text">
              Tax ID/EIN Application (2024)
            </span>
            <span className="logo-subtext">
              Approved 3rd Party eFile Provider
            </span>
          </span>
        )}
        {(domainName === "taxid-us" && subDomainName.includes("ein")) || (domainName === "ss4-ein-taxid" && skin === "taxid-ein") && (
          <span className="logo-title" style={{verticalAlign: "bottom"}}>
            <span className="logo-text">
            Tax ID/EIN Application (2024)
            </span>
          </span>
        )}
      </div>
    </Link>
  );
};

export default Logo;
